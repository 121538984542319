/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('pre-onboarding', {
      url: '',
      redirectTo: '.main',
      templateUrl:
        'sections/pre-onboarding/templates/acp-pre-onboarding.ng.html',
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.pre-onboarding" */ 'sections/pre-onboarding').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('pre-onboarding.main', {
      url: '/pre-onboarding',
      templateUrl:
        'sections/pre-onboarding/templates/acp-pre-onboarding-carousel.ng.html'
    });
}

export default states;
